import React, { useEffect } from "react"
import jwt_decode from "jwt-decode"
import { CookieBar } from "@mobi/ds"
import FeatureToggleWidget from "./components/FeatureToggleWidget"
import SEO from "components/SEO"
import { OfferProvider } from "hooks/useOffer"
import { ConclusionProvider } from "hooks/useConclusion"
import { RenegProvider } from "hooks/useReneg"
import { AuthOptinProvider } from "context/useAuthOptin"
import { CustomizeSelectContractsProvider } from "context/useCustomizeSelectContracts"
import storage from "utils/storage"
import { setCrossSellOrigin, setUtmSourceByQueryString } from "utils/crossSell"
import { DesenrolaProvider } from "context/useDesenrola"
import ga4 from "utils/trackings/ga4"
import { LayoutProvider } from "contexts/Layout"
import { LeadProvider } from "contexts/Lead"
import noIndexPage from "./utils/noIndexPage"

const Layout = ({ children }) => {
  const sessionToken = storage.session.getItem("bearerToken") || ""

  if (sessionToken?.length) {
    try {
      const decodedToken = jwt_decode(sessionToken)

      if (!Object.keys(decodedToken).length) return

      const tokenData = decodedToken?.data
      const isDataAnObject = typeof tokenData === "object"

      const data = isDataAnObject ? tokenData : JSON.parse(tokenData)

      if (!!data.lead_uuid) {
        storage.session.setItem("lead_uuid", data.lead_uuid)
      }
    } catch (error) {
      console.error("error to decoded token --", error)
    }
  }

  // useEffect(() => {
  //   if (
  //     typeof window.__VERSION__ === "undefined" &&
  //     typeof window.__TAG__ === "undefined"
  //   ) {
  //     console.group("%c " + "APP VERSION", "color: #ec7000")
  //     console.log("%c " + `VERSION: ${GIT_VERSION} `, "color: #253bff")
  //     console.log("%c " + `TAG: ${GIT_TAG_VERSION}`, "color: #253bff")
  //     console.groupEnd()

  //     window.__VERSION__ = GIT_VERSION
  //     window.__TAG__ = GIT_TAG_VERSION
  //   }
  // }, [])

  useEffect(() => {
    setUtmSourceByQueryString()
    setCrossSellOrigin()
    ga4.setDefaultParameters()
  }, [])

  return (
    <>
      <SEO meta={noIndexPage()} />

      <CookieBar
        initialText="Este site usa cookies e dados pessoais de acordo com os nossos "
        link="https://www.itau.com.br/privacidade/"
        textLink="Termos de Uso e Política de Privacidade"
        finalText="e, ao continuar navegando neste site, você declara estar ciente dessas condições."
      />
      <LayoutProvider>
        <OfferProvider>
          <ConclusionProvider>
            <LeadProvider>
              <RenegProvider>
                <DesenrolaProvider>
                  <AuthOptinProvider>
                    <CustomizeSelectContractsProvider>
                      <main>{children}</main>
                    </CustomizeSelectContractsProvider>
                    <FeatureToggleWidget />
                  </AuthOptinProvider>
                </DesenrolaProvider>
              </RenegProvider>
            </LeadProvider>
          </ConclusionProvider>
        </OfferProvider>
      </LayoutProvider>
    </>
  )
}

export default Layout
