import { BUILD_ENV } from "gatsby-env-variables"

import { datadogRum } from "@datadog/browser-rum"

const init = () => {
  console.log("Datadog - starterd")
  datadogRum.init({
    applicationId: "46211d08-3ce0-4c97-a9eb-4a1daebde55b",
    clientToken: "pub01bb88271be4d8d30e84f21e75ab9b5b",
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: "datadoghq.com",
    service: "itau-jn6-app-reneg-www",
    env: BUILD_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
  })
}
export default {
  init,
}
