import {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react"
import services, { setHeaderToken } from "services"
import handleSimulateRequest from "services/simulate"
import storage, { handleStorageError } from "utils/storage"
import { useOffer } from "./useOffer"
import { useLayout } from "../contexts/Layout"
import { useLead } from "../contexts/Lead"

import useGetVWOVariant from "./useGetVWOVariant"
import { DealResumeDetail } from "entities/DealResume"
import format from "@mobi/utils/formatters/number"
import { convertStringToNumber } from "utils/convertStringToNumber"
import { useConclusion } from "./useConclusion"
import { toISODate } from "utils/toIsoDate"
import { navigate } from "gatsby-link"

import { FormatDebtsGroups } from "entities/CashSimulation"
import featureToggle from "utils/featureToggle"
import accessibility, { scrollToElement } from "utils/accessibility"
import router from "utils/router"

import analytics from "@mobi/libraries/analytics"
import facebook from "utils/trackings/facebook"
import handleInternalTracking from "utils/trackings/handleInternalTracking"
import { getFromSafeStorage } from "utils/safeStorage"

import IconCircleCheck from "@mobi/ds/static/icons/outlined/confirmacao.svg"
import { calculateRoas } from "utils/roas/roas"
import { calculateRoasPDD } from "utils/roas/roasPdd"

const { isInterbankDebtActive } = featureToggle()

const DEFAULT_STEPS = [
  "/contratos",
  // "/contaBancaria", // adicionado por lógica do teste a/b
  "/parcelamento",
  "/resumo",
]

const PAYMENTS = {
  installment: "installmentSimulation",
  cash: "cashSimulation",
  hotDeal: "installmentSimulation",
}

export const PAYMENT_METHOD = {
  installmentSimulation: "installment",
  cashSimulation: "cash",
  delayedSimulation: "cash",
}

const RenegContext = createContext({})

export const useReneg = () => {
  const context = useContext(RenegContext)

  if (!context) {
    throw new Error(
      `useReneg hook must be used within a 'RenegProvider' component`
    )
  }

  return context
}

export const RenegProvider = ({ children }) => {
  const {
    setLoading,
    setLoaderText,
    setOrangeButtonTitle,
    setDisableOrangeButton,
  } = useLayout()

  const { debts, setDebts } = useLead()

  const { paymentOption, handleSetInstallment, handleSetCash, simulation } =
    useOffer()
  const { handleDealFinished } = useConclusion()

  const [renegSteps, setRenegSteps] = useState(DEFAULT_STEPS)

  const numberOfTotalSteps = renegSteps.length

  const stepsNumberMap = renegSteps.reduce((acc, step, index) => {
    acc[index] = step
    return acc
  }, {})

  const stepsNameMap = renegSteps.reduce((acc, step, index) => {
    acc[step] = index
    return acc
  }, {})

  const handleStepNumberAndName = (step) => {
    if (typeof step === "string") {
      return stepsNameMap[step]
    }

    return stepsNumberMap[step]
  }

  const [isToResimulate, setIsToResimulate] = useState(false)
  const [dealResume, setDealResume] = useState(
    handleStorageError(storage.session.getItem("dealResume"), [])
  )
  const [step, setStep] = useState(0)

  const hasResimulatedStorage = storage.session.getItem("hasResimulated")
  const [hasResimulated, setHasResimulated] = useState(
    hasResimulatedStorage || false
  )

  const [editBankAccount, setEditBankAccount] = useState(false)

  const isHotDealSession = storage.session.getItem("isHotDealSession")

  const isEditDebtGroup = storage.session.getItem("isEditGroup")

  const selectedBankOnAccountCreate = storage.session.getItem(
    "selectedBankOnAccountCreate"
  )
  const leadUUID = storage.session.getItem("lead_uuid")

  const [
    isPaymentFirstInstallmentTicketModalOpen,
    setIsPaymentFirstInstallmentTicketModalOpen,
  ] = useState(false)
  const [
    isGroupAvailableAccountDebtModalOpen,
    setIsGroupAvailableAccountDebtModalOpen,
  ] = useState(false)

  const isRemoveContractsPage = storage.session.getItem("isRemoveContractsPage")

  const contractsResponse = storage.session.getItem("contractsResponse")

  const isCustomizeDealFlow = handleStorageError(
    storage.session.getItem("isCustomizeDealFlow"),
    false
  )

  useEffect(() => {
    const currentPath = router.getPathName()
    const stepNumber = stepsNameMap[currentPath] || 0

    setStep(stepNumber)
  }, [])

  useEffect(() => {
    if (!!isCustomizeDealFlow) {
      const customSteps = [
        "/personalizar/forma-pagamento",
        "/personalizar/contratos",
        "/parcelamento",
        "/resumo",
      ]

      setRenegSteps(customSteps)
    } else {
      setRenegSteps(DEFAULT_STEPS)
    }
  }, [isCustomizeDealFlow])

  useEffect(() => {
    const debtGroupsValidate = isHotDealSession
      ? "hotDealSimulation"
      : paymentOption

    setDebts(storage.session.getItem(debtGroupsValidate || []))
  }, [paymentOption, isHotDealSession, simulation, isRemoveContractsPage])

  useEffect(() => {
    // Adding this IF to dont need to remove all interbank validation.
    // TODO: just remove the "canRunInterbankValidation" when interbank is back
    const canRunInterbankValidation = false

    if (canRunInterbankValidation) {
      const interbankABTest = useGetVWOVariant({
        name: "interbank_experiment",
        tries: 20,
        interval: 300,
      })

      const isInterbankVariant = interbankABTest === "1"

      storage.session.setItem("hasInterbank", isInterbankVariant)

      if (isInterbankDebtActive && isInterbankVariant) {
        const hasBankAccount = renegSteps.findIndex((step) => {
          return step === "/contaBancaria"
        })

        if (hasBankAccount === -1) {
          const newRenegSteps = renegSteps
          newRenegSteps.splice(1, 0, "/contaBancaria")

          setRenegSteps(newRenegSteps)
        }
      }
    }
  }, [])

  const handleDesenrola600Resimulate = () => {
    try {
      navigate("/parcelamento", {
        state: {
          showResimulatedAlert: true,
        },
      })

      setTimeout(() => {
        setLoading(false)
      }, 500)
    } catch (error) {
      console.error("error handleDesenrola600Resimulate |", error)
      throw new Error("handleDesenrola600Resimulate |", error)
    }
  }

  const handleCashFetchMount = ({ cash_simulation_response }) => {
    try {
      if (!cash_simulation_response)
        throw new Error("handleCashFetchMount | cash_simulation_response empty")

      const cashSimulationResponse = {
        cash_simulation_summary: cash_simulation_response?.summary,
        cash_debts_group: cash_simulation_response?.debts_group,
      }

      const parsedCash = handleSetCash(cashSimulationResponse)

      if (paymentOption === "delayedSimulation") {
        storage.session.setItem("delayedSimulation", parsedCash)
      }

      setDebts(parsedCash)
    } catch (error) {
      console.error("error handleCashFetchMount |", error)
      throw new Error("handleCashFetchMount |", error)
    }
  }

  const handleInstallmentFetchMount = ({
    installment_simulation_response,
    hasSelectedPaymentPlans = true,
  }) => {
    try {
      if (!installment_simulation_response)
        throw new Error(
          "handleInstallmentFetchMount | installment_simulation_response empty"
        )

      const installmentSimulationResponse = {
        installment_simulation_summary:
          installment_simulation_response?.summary,
        installment_debts_group: installment_simulation_response?.debts_group,
      }

      const isInstallment =
        paymentOption === PAYMENTS.installment && !isHotDealSession
      const storagePaymentOption = isHotDealSession
        ? "hotDealSimulation"
        : paymentOption
      const storageselectedDeal = storage.session.getItem(storagePaymentOption)
      const storageDebtsGroup =
        isInstallment && storageselectedDeal?.debtsGroups

      const paymentPlans = FormatDebtsGroups(
        installmentSimulationResponse.installment_debts_group,
        isHotDealSession,
        storageDebtsGroup
      ).filter(({ contracts }) =>
        debts.debtsGroups.map((group) =>
          group.contracts.find(({ id }) => {
            return {
              ...contracts.find(
                ({ external_contract_id }) => id === external_contract_id
              ),
              simulationId: group.simulation_id,
            }
          })
        )
      )

      const selectedPaymentPlans = paymentPlans.map(
        ({ simulationId, contracts, paymentPlan, selectedPaymentPlan }) => {
          const hotDealPaymentPlan = paymentPlan.filter(
            (payment) => payment.installmentNumber === 6
          )?.[0]
          const firstPaymentPlan = [...paymentPlan][0]
          const lastPaymentPlan = [...paymentPlan].pop()

          const selectedInstallment = paymentPlan.find(
            ({ installmentNumber }) =>
              installmentNumber === selectedPaymentPlan.installmentNumber
          )

          const hotDealSessionPaymentPlan =
            hotDealPaymentPlan || firstPaymentPlan
          const installmentPaymentPlan = selectedInstallment || lastPaymentPlan

          const paymentPlanSelected = isHotDealSession
            ? hotDealSessionPaymentPlan
            : installmentPaymentPlan

          return {
            selectedPaymentPlan: paymentPlanSelected,
            simulationId,
            contracts,
          }
        }
      )

      storage.session.setItem("isHotDealSession", false)

      let parsedDataAditionalInfo = {
        isHotDeal: false,
      }

      if (hasSelectedPaymentPlans) {
        parsedDataAditionalInfo = {
          ...parsedDataAditionalInfo,
          selectedPaymentPlans,
        }
      }

      const parsedInstallment = handleSetInstallment(
        installmentSimulationResponse,
        parsedDataAditionalInfo
      )

      setDebts(parsedInstallment)

      storage.session.setItem("hotDealSimulation", parsedInstallment)
      storage.session.setItem("installmentSimulation", parsedInstallment)
    } catch (error) {
      console.error("error handleInstallmentFetchMount |", error)
      throw new Error("handleInstallmentFetchMount |", error)
    }
  }

  const handleResimulate = useCallback(
    async (contractsWithoutDesenrola600) => {
      try {
        setLoading(true)
        setLoaderText({
          introText: "Por favor, aguarde",
          mainText: "Estamos calculando seu acordo |;)|",
        })

        const contractsResponse = storage.session.getItem("contractsResponse")
        const removedContracts = storage.session.getItem("removedContracts")

        handleInternalTracking("Deal_ressimulate")
        setIsToResimulate(false)

        const groupsInfo = debts.debtsGroups
          .map(({ selectedDate, defaultDate, contracts }) => {
            const filteredContracts = contracts.filter(
              (contract) => !removedContracts?.includes(contract.id)
            )
            const selectedContracts =
              contractsWithoutDesenrola600 || filteredContracts

            return {
              payment_date:
                selectedDate === defaultDate ? "" : toISODate(selectedDate),
              contracts: selectedContracts.flatMap((contract) =>
                contractsResponse?.origins?.reneg
                  .filter(
                    (group) =>
                      group.CustomData.contract_number === Number(contract.id)
                  )
                  .map((item) => item.ExternalContractID)
              ),
            }
          })
          .filter((filterPayload) => filterPayload.contracts.length)

        const payload = {
          session_id: contractsResponse?.session_id,
          simulation_type: PAYMENT_METHOD[paymentOption],
          groups: groupsInfo,
        }

        const { cash_simulation_response, installment_simulation_response } =
          await handleSimulateRequest({ payload })

        storage.session.setItem("removedContracts", [])
        storage.session.setItem("removedDebts", [])

        if (paymentOption === PAYMENTS.installment) {
          handleInstallmentFetchMount({ installment_simulation_response })
        } else {
          handleCashFetchMount({ cash_simulation_response })
        }

        setOrangeButtonTitle("Continuar")
        storage.session.setItem("hasResimulated", true)
        setHasResimulated(true)

        if (contractsWithoutDesenrola600) {
          handleDesenrola600Resimulate()
          return
        }

        setLoading(false)
      } catch (error) {
        console.error("error handleResimulate |", error)
        setLoading(false)

        const _errorType = {
          hotDeal: {
            name: "hotDealRessimulateError",
            uuid: "abcdf6c7-30e6-489f-ad19-70fbbbd0087c",
            gaText: "VIEW:hotDealRessimulateError",
          },
          general: {
            name: "ressimulateError",
            uuid: "d63ba0e4-161b-479f-b889-732667bfed97",
            gaText: "VIEW:ressimulateError",
          },
        }

        const currentFlow = isHotDealSession ? "hotDeal" : "general"

        analytics.track("gaButtonClick", {
          id: _errorType[currentFlow].uuid,
          text: _errorType[currentFlow].gaText,
        })

        if (!error.redirected) {
          navigate("/error")
        }
      }
    },
    [
      paymentOption,
      setLoaderText,
      setLoading,
      handleSetInstallment,
      handleSetCash,
      debts,
      dealResume,
      setDealResume,
      isHotDealSession,
    ]
  )

  const handleGetResume = useCallback(async () => {
    try {
      const isInstallment = paymentOption === PAYMENTS.installment
      const sessionId = storage.session.getItem("contracts_session_id")
      const accessToken = storage.local.getItem("sessionAccessToken")

      const summaries = debts?.debtsGroups.map((debtGroup) => {
        let payload = {
          simulation_id: Number(debtGroup.simulationId),
        }

        if (isInstallment) {
          payload = {
            ...payload,
            plan_id: Number(debtGroup?.selectedPaymentPlan?.id),
          }
        }

        return payload
      })

      if (!summaries.length) {
        console.error("summaries list is empty")
        throw new Error("Summaries list is empty")
      }

      const bodyRequest = {
        session_id: sessionId,
        summaries,
        summary_type: isInstallment ? "installment" : "cash",
      }

      setLoading(true)

      setLoaderText({
        introText: "Por favor, aguarde",
        mainText: "Estamos gerando seu resumo |;)|",
      })

      const { data } = await services.bff.post("/summary", bodyRequest, {
        headers: {
          Token: `Bearer ${accessToken}`,
        },
      })

      const dealResumeResponse = data?.reneg

      const hasData = Object.keys(data || {}).length
      const hasDealResumeResponse =
        Object.keys(dealResumeResponse || {}).length &&
        !dealResumeResponse?.error
      const hasSummaries = dealResumeResponse?.summaries?.length

      if (!hasData || !hasDealResumeResponse || !hasSummaries) {
        const errorsList = [
          {
            errorName: "emptySummaryResponse",
            errorMessage: "reneg.summaries is empty or null",
          },
          {
            errorName: "errorSummaryResponse",
            errorMessage: "Error on summary response",
          },
        ]

        const currentError =
          !dealResumeResponse?.summaries ||
          dealResumeResponse.summaries.length === 0
            ? errorsList[0]
            : errorsList[1]

        analytics.track("gaButtonClick", {
          id: "00ce7816-2a57-4ed0-aa5b-2ebc38b3f14f",
          text: `VIEW:${currentError.errorMessage}`,
        })

        throw new Error("Summary response error")
      }

      const dealResumeDetail = DealResumeDetail(dealResumeResponse)

      setDealResume(dealResumeDetail)
      storage.session.setItem("dealResume", dealResumeDetail)
    } catch (e) {
      setStep(handleStepNumberAndName("/contratos"))
      navigate("/error")
    }
  }, [
    paymentOption,
    debts,
    setLoading,
    setLoaderText,
    dealResume,
    isEditDebtGroup,
    isHotDealSession,
    setDealResume,
  ])

  const handleDeleteGroup = useCallback(
    (summaryId) => {
      const filterDealResume = dealResume?.summaries.filter(
        (deal) => deal.summaryId !== summaryId
      )

      setDealResume(filterDealResume)
      storage.session.setItem("dealResume", filterDealResume)

      if (filterDealResume?.length) {
        const totals = filterDealResume.reduce((sum, current) => {
          return {
            originalValue: format({
              value:
                convertStringToNumber(sum.originalValue) +
                convertStringToNumber(current.originalValue),
              style: "currency",
            }).substring(3),
            currentValue: format({
              value:
                convertStringToNumber(sum.totalValue) +
                convertStringToNumber(current.totalValue),
              style: "currency",
            }).substring(3),
          }
        })

        setDebts({
          ...debts,
          originalValue: totals.originalValue,
          currentValue: totals.currentValue || totals.totalValue,
        })
      } else {
        setDisableOrangeButton(true)
        setDebts({
          ...debts,
          originalValue: format({
            value: 0,
            style: "currency",
          }).substring(3),
          currentValue: format({
            value: 0,
            style: "currency",
          }).substring(3),
        })
      }
    },
    [dealResume, debts]
  )

  const handleResetDebtsGroups = (debtsGroups) => {
    return debtsGroups?.map((debt) => {
      const hotDealPaymentPlan = debt.paymentPlan.filter(
        (payment) => payment.installmentNumber === 6
      )?.[0]
      const firstPaymentPlan = [...debt.paymentPlan][0]
      const lastPaymentPlan = [...debt.paymentPlan].pop()
      const { recommendedInstallmentNumber } = debt

      const recommendedPaymentPlan = debt.paymentPlan.find(
        (payment) => payment.installmentNumber === recommendedInstallmentNumber
      )

      const installmentPaymentPlan = recommendedInstallmentNumber
        ? recommendedPaymentPlan
        : lastPaymentPlan

      const paymentPlanSelected = isHotDealSession
        ? hotDealPaymentPlan || firstPaymentPlan
        : installmentPaymentPlan

      return {
        ...debt,
        selectedPaymentPlan: paymentPlanSelected,
      }
    })
  }

  const handleResetTotalValues = useCallback(() => {
    const storageDebtsValidate = isHotDealSession
      ? "hotDealSimulation"
      : paymentOption
    const storageDebts = storage.session.getItem(storageDebtsValidate)

    setDisableOrangeButton(false)

    const debtsOriginalValue =
      storageDebts.originalTotalValue || storageDebts.originalValue
    const debtsCurrentValue =
      storageDebts.currentTotalValue || storageDebts.currentValue

    const newDebts = {
      ...debts,
      originalValue: debtsOriginalValue,
      currentValue: debtsCurrentValue,
    }

    if (paymentOption === PAYMENTS.installment) {
      newDebts.debtsGroups = handleResetDebtsGroups(debts.debtsGroups)
    }

    storage.session.setItem(storageDebtsValidate, newDebts)
    setDebts(newDebts)
  }, [paymentOption, debts, isHotDealSession])

  const handleMakeDeal = useCallback(
    async (loader = {}) => {
      const isInstallment = paymentOption === PAYMENTS.installment
      const accessToken = storage.local.getItem("sessionAccessToken")
      const sessionId = storage.session.getItem("contracts_session_id")
      const authOptInID = storage.session.getItem("authOptInID")

      await setHeaderToken(accessToken)

      try {
        setLoaderText({
          introText: loader?.introText || "Por favor, aguarde",
          mainText: loader?.mainText || "Estamos finalizando seu acordo |;)|",
        })

        setLoading(true)

        const agreementsPayload = dealResume?.summaries.map((deal) => ({
          origin: "reneg",
          summary_id: isInstallment
            ? Number(deal?.summaryId)
            : Number(deal?.generalConditionId),
          simulation_type: `${isInstallment ? "installment" : "cash"}`,
        }))

        const bodyRequest = {
          session_id: sessionId,
          agreements: agreementsPayload,
        }

        const hasSummaryId = bodyRequest["agreements"]?.every(
          (deal) => !!deal["summary_id"]
        )

        const requestInfo = {
          useRecaptcha: true,
          headers: {
            Token: `Bearer ${accessToken}`,
          },
        }

        if (authOptInID) {
          bodyRequest["optin_ids"] = authOptInID
        }

        if (hasSummaryId) {
          const { data: dealFinished } = await services.bff.post(
            `/agreements`,
            bodyRequest,
            requestInfo
          )
          setHeaderToken(accessToken)

          const parsedDealFinished = await handleDealFinished(dealFinished)

          return parsedDealFinished
        } else {
          console.error("summary_id or generalConditionId is empty")

          setLoading(false)
          navigate("/error")
        }
      } catch (e) {
        setLoading(false)

        if (!e.redirected) {
          navigate("/error")
        }

        throw e
      }
    },
    [paymentOption, dealResume, handleDealFinished, setLoaderText, setLoading]
  )

  const hotDealSkipToReview = () => {
    const hotDealSummarySimulation = storage.session.getItem(
      "hotDealSummarySimulation" || []
    )

    setLoaderText({
      introText: "Por favor, aguarde",
      mainText: "Estamos gerando seu resumo |;)|",
    })
    setLoading(true)

    storage.session.setItem("fromOffers", true)
    storage.session.setItem("isHotDealSession", true)

    const dealResumeDetail = DealResumeDetail(hotDealSummarySimulation)
    setDealResume(dealResumeDetail)
    storage.session.setItem("dealResume", dealResumeDetail)
  }

  const handleEditBankAccount = useCallback(() => {
    setEditBankAccount(true)
    setStep(handleStepNumberAndName("/contaBancaria"))
  }, [])

  const handleInterbank = (debts) => {
    const hasAtLeastOneInterbankGroup =
      debts?.debtsGroups?.findIndex((group) => group.is_interbank_allowed) !==
      -1

    const hasInterbankAllowed =
      hasAtLeastOneInterbankGroup && paymentOption === "installmentSimulation"

    storage.session.setItem("hasInterbankAllowed", hasInterbankAllowed)

    return hasInterbankAllowed
  }

  const handleReviewDeal = async (loader = {}) => {
    const parsedDealFinished = await handleMakeDeal(loader)

    const agreementValue = parsedDealFinished
      .reduce((acc, curr) => {
        return acc + curr.currentValueRaw
      }, 0)
      .toFixed(2)

    const roasValue = calculateRoas(agreementValue)
    const pddRoasValue = calculateRoasPDD(agreementValue)

    const roasObj = {
      receita_esperada: roasValue,
      receita_esperada_pdd: pddRoasValue,
      agreement_value: agreementValue,
    }

    analytics.track("gaGenericEvent", {
      custom: roasObj,
    })

    analytics.track("segmentGenericEvent", {
      name: "receita_esperada_pdd",
      eventParams: {
        ...roasObj,
      },
    })

    const conversionGoogleAdsScript = document.createElement("script")
    conversionGoogleAdsScript.defer = true
    conversionGoogleAdsScript.innerHTML = `gtag('event', 'conversion', {
      'send_to': 'AW-10854320020/rsUuCJrclLsDEJSP37co'
    });`

    facebook.trackPurchase({ value: agreementValue })

    document.body.appendChild(conversionGoogleAdsScript)

    handleInternalTracking("Deal_finished")

    storage.session.setItem("fromReneg", true)

    navigate("/conclusao")
  }

  const groupsTrack = (debtGroups) => {
    let transactionDetails
    const leadType = storage.session.getItem("lead_type")

    if (leadType) {
      transactionDetails = leadType
    }

    if (leadType === "PF") {
      const isCorrentista = storage.session.getItem("is_correntista")

      if (!isCorrentista) {
        transactionDetails = "PF e NCC"
      }
    }

    if (transactionDetails) {
      storage.session.setItem("transaction_details", transactionDetails)
    }

    debtGroups.forEach((group, index) => {
      analytics.track("gaButtonClick", {
        id: index,
        text: "SelecaoDaDivida",
        transactionName: group.simulationId,
        transactionDetails,
        idproposta: leadUUID,
        vba: group.currentValue,
        volsim: group.originalValue,
        whatevervariable: group?.contracts?.length,
      })
    })
  }

  const closeAlert = (timeMs = 5000) =>
    setTimeout(() => {
      document.querySelector(".ds-alert__close")?.click()
    }, timeMs)

  const handleCustomSimulateFetch = useCallback(async () => {
    try {
      setLoading(true)
      setLoaderText({
        introText: "aguarde um instante",
        mainText: "estamos agrupando seus contratos",
      })

      const customizeSelectedContracts = handleStorageError(
        storage.session.getItem("customizeSelectedContracts"),
        []
      )

      const date = new Date()
      const dateToLocale = date.toLocaleDateString()
      const currentDate = toISODate(dateToLocale)

      const groupsInfo = debts.debtsGroups
        .map(({ contracts }) => {
          const selectedContracts = contracts.filter((contract) =>
            customizeSelectedContracts?.includes(contract.id)
          )

          return {
            payment_date: currentDate,
            contracts: selectedContracts.flatMap((contract) =>
              contractsResponse?.origins?.reneg
                .filter(
                  (group) =>
                    group.CustomData.contract_number === Number(contract.id)
                )
                .map((item) => item.ExternalContractID)
            ),
          }
        })
        .filter((filterPayload) => filterPayload.contracts.length)

      const payload = {
        session_id: contractsResponse?.session_id,
        simulation_type: PAYMENT_METHOD[paymentOption],
        groups: groupsInfo,
      }

      const {
        cash_simulation_response = {},
        installment_simulation_response = {},
      } = await handleSimulateRequest({ payload })

      if (paymentOption === PAYMENTS.installment) {
        handleInstallmentFetchMount({
          installment_simulation_response,
          hasSelectedPaymentPlans: false,
        })
      } else {
        handleCashFetchMount({ cash_simulation_response })
      }

      setOrangeButtonTitle("Continuar")
      setLoading(false)
    } catch (error) {
      console.error(`handleCustomSimulateFetch | ${error}`)
      setLoading(false)

      navigate("/error")
    }
  }, [
    paymentOption,
    setLoaderText,
    setLoading,
    handleSetInstallment,
    handleSetCash,
    debts,
    dealResume,
    setDealResume,
    isHotDealSession,
  ])

  const nextStep = useCallback(
    async (Alert) => {
      scrollToElement(".steps-layout__header")

      const hasInterbank = storage.session.getItem("hasInterbank")

      const paymentOption = storage.session.getItem("paymentOption")

      const paymentOptionTitle = {
        cashSimulation: "pagamento à vista",
        installmentSimulation: "pagamento parcelado",
      }

      const stepName = handleStepNumberAndName(step).replace("/", "")
      if (isToResimulate) {
        analytics.track("segmentButtonClick", {
          name: `Ressimular:${stepName}`,
          id: "a68d2dd5-c70f-465d-8c05-19bd6faa3692",
        })
      } else if (
        handleStepNumberAndName(step) === "/personalizar/forma-pagamento"
      ) {
        analytics.track("segmentButtonClick", {
          name: `Continuar:${stepName}:${paymentOptionTitle[paymentOption]}`,
          id: "29a17c0e-994a-49b4-aef5-1e83c34d82d4",
        })
      } else {
        analytics.track("segmentButtonClick", {
          name: `Continuar:${stepName}`,
          id: "112aba74-82dd-4784-9b6e-5217e7d96359",
        })
      }

      switch (step) {
        case handleStepNumberAndName("/contratos"): {
          if (isToResimulate) {
            await handleResimulate()

            break
          }

          const debts = storage.session.getItem(paymentOption)

          groupsTrack(debts?.debtsGroups)

          if (isInterbankDebtActive && hasInterbank) {
            if (handleInterbank(debts)) {
              navigate("/contaBancaria")
              setStep(handleStepNumberAndName("/contaBancaria"))
            } else {
              const newStep = handleStepNumberAndName("/contratos") + 2

              navigate(handleStepNumberAndName(newStep))
              setStep(newStep)
            }
          } else {
            const newStep = handleStepNumberAndName("/contratos") + 1

            navigate(handleStepNumberAndName(newStep))
            setStep(newStep)
          }

          accessibility.focusInElement(".ds-header__button-back")

          break
        }
        case handleStepNumberAndName("/personalizar/forma-pagamento"): {
          setStep(handleStepNumberAndName("/personalizar/contratos"))
          navigate("/personalizar/contratos")

          accessibility.focusInElement(".ds-header__button-back")

          break
        }
        case handleStepNumberAndName("/personalizar/contratos"): {
          await handleCustomSimulateFetch()
          navigate("/parcelamento")
          setStep(handleStepNumberAndName("/parcelamento"))

          accessibility.focusInElement(".ds-header__button-back")

          break
        }
        case handleStepNumberAndName("/parcelamento"): {
          if (isToResimulate) {
            await handleResimulate()

            const hasLeadResimulated = hasResimulatedStorage || hasResimulated

            if (hasLeadResimulated) {
              Alert.success(
                "Os valores do seu acordo foram atualizados com sucesso.",
                {
                  icon: (
                    <IconCircleCheck
                      width={24}
                      height={24}
                      className="circle-check-icon"
                    />
                  ),
                }
              )

              closeAlert()

              storage.session.setItem("hasResimulated", false)
              setHasResimulated(false)
            }

            break
          }

          await handleGetResume()

          const newStep = handleStepNumberAndName("/parcelamento") + 1

          navigate(handleStepNumberAndName(newStep))
          setStep(newStep)

          accessibility.focusInElement(".ds-header__button-back")

          break
        }
        case handleStepNumberAndName("/resumo"): {
          if (selectedBankOnAccountCreate) {
            const debts = storage.session.getItem(paymentOption)
            const debtGroups = debts?.debtsGroups

            const allGroupsInterbankAllowed = debtGroups?.every(
              (group) => group.is_interbank_allowed
            )
            const interbankAllowedList = debtGroups?.filter(
              (group) => group.is_interbank_allowed
            )
            const hasOnlyOneGroupWithInterbankAllowed =
              interbankAllowedList.length === 1

            if (allGroupsInterbankAllowed) {
              // se todos os acordos estão disponíveis para débito em conta
              setIsPaymentFirstInstallmentTicketModalOpen(true)
              break
            }

            if (hasOnlyOneGroupWithInterbankAllowed) {
              // se tiver apenas um grupo disponível para débito em conta
              setIsGroupAvailableAccountDebtModalOpen(true)
              break
            }
          } else {
            await handleReviewDeal()
          }

          break
        }
        default:
          console.error("No case statement")
      }
    },
    [
      step,
      handleGetResume,
      handleResimulate,
      handleMakeDeal,
      isToResimulate,
      paymentOption,
      hasResimulatedStorage,
      hasResimulated,
      editBankAccount,
      renegSteps,
    ]
  )

  const handleGoBack = useCallback(() => {
    const hasInterbank = storage.session.getItem("hasInterbank")

    let newStep = step - 1

    if (["/parcelamento"].includes(handleStepNumberAndName(step))) {
      handleResetTotalValues()
    }

    setIsToResimulate(false)
    setDisableOrangeButton(false)
    setOrangeButtonTitle("Continuar")

    if (newStep < 0) {
      storage.session.removeItem("removedDebts")
      storage.session.removeItem("removedContracts")

      storage.session.removeItem("isCustomizeDealFlow")
      storage.session.removeItem("customizeSelectedContracts")

      setLoaderText({
        introText: "Aguarde um instante!",
        mainText: "Estamos calculando seus descontos ;)",
      })
      setLoading(true)

      return setTimeout(() => {
        navigate("/ofertas")
      }, 900)
    }

    scrollToElement(".steps-layout__header")
    accessibility.focusInElement(".ds-header__button-back")

    if (isInterbankDebtActive && hasInterbank) {
      if (
        editBankAccount &&
        step === handleStepNumberAndName("/contaBancaria")
      ) {
        setEditBankAccount(false)
        newStep = handleStepNumberAndName("/resumo")
      }

      const hasInterbankAllowed = storage.session.getItem("hasInterbankAllowed")
      if (
        !hasInterbankAllowed &&
        step === handleStepNumberAndName("/parcelamento")
      ) {
        navigate(handleStepNumberAndName(newStep - 1))

        newStep -= 1
      }
    }

    navigate(handleStepNumberAndName(newStep))
    setStep(newStep)
  }, [
    handleResetTotalValues,
    setLoading,
    setLoaderText,
    editBankAccount,
    renegSteps,
    step,
  ])

  const prevStep = async () => {
    setDisableOrangeButton(false)
    setOrangeButtonTitle("Continuar")

    const isReview = step === handleStepNumberAndName("/resumo")
    const isContaBancaria = step === handleStepNumberAndName("/contaBancaria")
    const interbankAccount = await getFromSafeStorage("debtAccount")

    if (isHotDealSession) {
      if (isReview && interbankAccount) {
        navigate("/contaBancaria")
        return
      }
      if (isReview || isContaBancaria) {
        storage.session.setItem("isHotDealSession", false)
        storage.session.setItem("isEditGroup", false)
        setStep(0)
        navigate("/ofertas")
        return
      }
    }

    handleGoBack()
  }

  return (
    <RenegContext.Provider
      value={{
        dealResume,
        isToResimulate,
        step,
        hasResimulated,
        isPaymentFirstInstallmentTicketModalOpen,
        isGroupAvailableAccountDebtModalOpen,
        numberOfTotalSteps,
        setIsToResimulate,
        handleResimulate,
        handleGetResume,
        handleDeleteGroup,
        handleResetTotalValues,
        handleMakeDeal,
        setStep,
        setDealResume,
        setHasResimulated,
        setIsPaymentFirstInstallmentTicketModalOpen,
        setIsGroupAvailableAccountDebtModalOpen,
        handleStepNumberAndName,
        handleEditBankAccount,
        setEditBankAccount,
        editBankAccount,
        hotDealSkipToReview,
        handleInterbank,
        nextStep,
        prevStep,
        handleReviewDeal,
        renegSteps,
      }}
    >
      {children}
    </RenegContext.Provider>
  )
}
